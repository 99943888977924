@tailwind base;
@tailwind components;
@tailwind utilities;

.icon {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}
.rotate {
  transform: rotate(180deg);
}
.fade {
  opacity: 0;
}
